<template>
  <v-card
    flat
    class="pa-0 mt-2"
  >
    <v-card-text>
      <v-form
        ref="form"
        class="multi-col-validation mt-6"
        @submit.prevent="handleFormSubmit"
      >
        <v-row>
          <v-col
            cols="12"
            xs="12"
            sm="6"
          >
            <v-text-field
              v-model="userSocialLocal.whatsapp"
              outlined
              :label="$t('AccountSettings.body.tab3.form.whatsapp')"
              hide-details="auto"
              :error-messages="errorMessages.whatsapp"
              :rules="[]"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            xs="12"
            sm="6"
          >
            <v-text-field
              v-model="userSocialLocal.telegram"
              outlined
              :label="$t('AccountSettings.body.tab3.form.telegram')"
              hide-details="auto"
              :error-messages="errorMessages.telegram"
              :rules="[]"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            xs="12"
            sm="6"
          >
            <v-text-field
              v-model="userSocialLocal.instagram"
              outlined
              :label="$t('AccountSettings.body.tab3.form.facebook')"
              hide-details="auto"
              :error-messages="errorMessages.instagram"
              :rules="[]"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            xs="12"
            sm="6"
          >
            <v-text-field
              v-model="userSocialLocal.facebook"
              outlined
              :label="$t('AccountSettings.body.tab3.form.facebook')"
              hide-details="auto"
              :error-messages="errorMessages.facebook"
              :rules="[]"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            xs="12"
            sm="6"
          >
            <v-text-field
              v-model="userSocialLocal.linkedin"
              outlined
              :label="$t('AccountSettings.body.tab3.form.linkedin')"
              hide-details="auto"
              :error-messages="errorMessages.linkedin"
              :rules="[]"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-btn
              color="primary"
              type="submit"
              class="me-3 mt-4"
            >
              <div v-if="loading">
                <v-progress-circular
                  indeterminate
                  color="red"
                  @click.prevent="onCancelRequest"
                >
                  <v-icon>
                    {{ icons.mdiClose }}
                  </v-icon>
                </v-progress-circular>
              </div>
              <span v-else>{{ $t('AccountSettings.body.tab3.form.submit') }}</span>
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              class="mt-4"
              type="reset"
              @click.prevent="resetForm"
            >
              {{ $t('AccountSettings.body.tab3.form.reset') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { ref } from '@vue/composition-api'

// Services
import useUser from '@/services/api/modules/user/user'
import useUserHandler from '@/views/apps/user/useUserHandler'

export default {
  props: {
    userData: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const { sendUserNotification } = useUserHandler()

    // To view converter .....
    const converter = user => {
      // const userSocialInit = JSON.parse(JSON.stringify(user)) // Clone
      const userSocial = {
        telegram: user?.telegram || '',
        whatsapp: user?.whatsapp || '',
        instagram: user?.instagram || '',
        facebook: user?.facebook || '',
        linkedin: user?.linkedin || '',
      }

      return userSocial
    }

    const userSocialLocal = ref(JSON.parse(JSON.stringify(converter(props.userData))))
    const resetForm = () => {
      userSocialLocal.value = JSON.parse(JSON.stringify(converter(props.userData)))
    }
    const errorMessages = ref([])

    // Form
    const form = ref(null)
    const loading = ref(false)

    // Events
    const handleFormSubmit = () => {
      const isFormValid = form.value.validate()

      if (!isFormValid) return

      // eslint-disable-next-line no-use-before-define
      onSubmit()
    }

    const onSubmit = () => {
      loading.value = true
      useUser
        .updateUserSocialLinks({
          whatsapp: userSocialLocal.value.whatsapp,
          instagram: userSocialLocal.value.instagram,
          facebook: userSocialLocal.value.facebook,
          linkedin: userSocialLocal.value.linkedin,
          telegram: userSocialLocal.value.telegram,
        })
        .then(response => {
          const { user } = response.data
          if (user) {
            userSocialLocal.value = converter(user)
          }
          sendUserNotification('Se ha cambiado correctamente', 'success')
        })
        .catch(error => {
          // debugger
          // Checking... si es un error de dominio lo pasamos al formulario
          if (error.response && error.response.data && error.response.data.error) {
            // refFormObserver.value.setErrors(error.response.data.error)
            errorMessages.value = error.response.data.error

            sendUserNotification(error.response.data.error.message, 'error')
          }
        })
        .finally(() => {
          loading.value = false
        })
    }

    return {
      // Form validation
      form,
      handleFormSubmit,
      resetForm,

      // Interface events
      loading,

      // Data
      userSocialLocal,
      errorMessages,
    }
  },
}
</script>
