<template>
  <v-card
    flat
    class="pa-0 mt-2"
  >
    <v-form
      ref="form"
      class="multi-col-validation mt-6"
      @submit.prevent="handleFormSubmit"
    >
      <div class="px-3">
        <v-card-text class="pt-5">
          <v-row>
            <v-col
              cols="12"
              sm="8"
              md="6"
            >
              <!-- current password -->
              <v-text-field
                v-model="userPasswordResetLocal.passwordOld"
                :type="userPasswordResetLocal.isPasswordOldVisible ? 'text' : 'password'"
                :append-icon="userPasswordResetLocal.isPasswordOldVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                :label="$t('AccountSettings.body.tab2.form.password_current')"
                outlined
                dense
                :error-messages="errorMessages.passwordOld"
                :rules="[required]"
                @click:append="userPasswordResetLocal.isPasswordOldVisible = !userPasswordResetLocal.isPasswordOldVisible"
              ></v-text-field>

              <!-- new password -->
              <!-- hint="Make sure it's at least 8 characters." -->
              <!-- persistent-hint -->
              <v-text-field
                v-model="userPasswordResetLocal.passwordNew"
                :type="userPasswordResetLocal.isPasswordNewVisible ? 'text' : 'password'"
                :append-icon="userPasswordResetLocal.isPasswordNewVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                :label="$t('AccountSettings.body.tab2.form.password_new')"
                outlined
                dense
                :error-messages="errorMessages.passwordNew"
                :rules="[required]"
                @click:append="userPasswordResetLocal.isPasswordNewVisible = !userPasswordResetLocal.isPasswordNewVisible"
              ></v-text-field>

              <!-- confirm password -->
              <v-text-field
                v-model="userPasswordResetLocal.passwordRetype"
                :type="userPasswordResetLocal.isPasswordRetypeVisible ? 'text' : 'password'"
                :append-icon="userPasswordResetLocal.isPasswordRetypeVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                :label="$t('AccountSettings.body.tab2.form.password_confirm')"
                outlined
                dense
                class="mt-3"
                :error-messages="errorMessages.passwordRetype"
                :rules="[required, confirmedValidator(userPasswordResetLocal.passwordNew, userPasswordResetLocal.passwordRetype)]"
                @click:append="userPasswordResetLocal.isPasswordRetypeVisible = !userPasswordResetLocal.isPasswordRetypeVisible"
              ></v-text-field>

              <v-btn
                color="primary"
                type="submit"
                class="me-3 mt-4"
              >
                <div v-if="loading">
                  <v-progress-circular
                    indeterminate
                    color="red"
                    @click.prevent="onCancelRequest"
                  >
                    <v-icon>
                      {{ icons.mdiClose }}
                    </v-icon>
                  </v-progress-circular>
                </div>
                <span v-else>{{ $t('AccountSettings.body.tab2.form.submit') }}</span>
              </v-btn>
              <v-btn
                color="secondary"
                outlined
                class="mt-4"
                type="reset"
                @click.prevent="resetForm"
              >
                {{ $t('AccountSettings.body.tab2.form.reset') }}
              </v-btn>
            </v-col>

            <v-col
              cols="12"
              sm="4"
              md="6"
              class="d-none d-sm-flex justify-center position-relative"
            >
              <v-img
                contain
                max-width="170"
                src="@/assets/images/3d-characters/pose-m-1.png"
                class="security-character"
              ></v-img>
            </v-col>
          </v-row>
        </v-card-text>
      </div>
    </v-form>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { confirmedValidator, required } from '@core/utils/validation'
import {
  mdiEyeOffOutline, mdiEyeOutline, mdiKeyOutline, mdiLockOpenOutline,
} from '@mdi/js'
import { ref } from '@vue/composition-api'

// Components
// Services
import useJwt from '@/services/api/modules/auth/useJwt'
import useUser from '@/services/api/modules/user/user'
import useUserHandler from '@/views/apps/user/useUserHandler'

export default {
  setup() {
    const { sendUserNotification } = useUserHandler()

    // To view converter .....
    const converter = () => {
      // const userSocialInit = JSON.parse(JSON.stringify(user)) // Clone
      const userPasswordReset = {
        passwordOld: '',
        passwordNew: '',
        passwordRetype: '',
        isPasswordOldVisible: false,
        isPasswordNewVisible: false,
        isPasswordRetypeVisible: false,
      }

      return userPasswordReset
    }

    // Data
    const userPasswordResetLocal = ref(converter())
    const resetForm = () => {
      userPasswordResetLocal.value = converter()
    }
    const errorMessages = ref([])

    // Form
    const form = ref(null)
    const loading = ref(false)

    // Events
    const handleFormSubmit = () => {
      const isFormValid = form.value.validate()

      if (!isFormValid) return

      // eslint-disable-next-line no-use-before-define
      onSubmit()
    }

    const onSubmit = () => {
      loading.value = true
      useUser
        .resetPassword({
          passwordOld: userPasswordResetLocal.value.passwordOld,
          passwordNew: userPasswordResetLocal.value.passwordNew,
          passwordRetype: userPasswordResetLocal.value.passwordRetype,
        })
        .then(response => {
          // debugger
          const { userData } = response.data
          if (userData) {
            useJwt.setRememberMe(false, null, null) // Eliminamos el remember

            // Reset form and errors
            // resetForm()
            errorMessages.value = []
          } else {
            // refFormObserver.value.setErrors(response.data.error)
            errorMessages.value = response.data.error
          }
          sendUserNotification('Se ha cambiado correctamente', 'success')
        })
        .catch(error => {
          // Checking... si es un error de dominio lo pasamos al formulario
          if (error.response && error.response.data && error.response.data.error) {
            // refFormObserver.value.setErrors(error.response.data.error)
            errorMessages.value = error.response.data.error

            sendUserNotification(error.response.data.error.message, 'error')
          }
        })
        .finally(() => {
          loading.value = false
        })
    }

    return {
      // Form validation
      form,
      handleFormSubmit,
      resetForm,
      required,
      confirmedValidator,

      // Interface events
      loading,

      // Data
      userPasswordResetLocal,
      errorMessages,

      // currentPassword,
      // newPassword,
      // cPassword,

      // UI
      // isCurrentPasswordVisible,
      // isNewPasswordVisible,
      // isCPasswordVisible,

      icons: {
        mdiKeyOutline,
        mdiLockOpenOutline,
        mdiEyeOffOutline,
        mdiEyeOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.two-factor-auth {
  max-width: 25rem;
}
.security-character {
  position: absolute;
  bottom: -0.5rem;
}
</style>
