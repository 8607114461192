<template>
  <v-card id="account-setting-card">
    <!-- tabs -->
    <v-tabs
      v-model="tab"
      fixed-tabs
      :hide-slider="false"
      icons-and-text
      centered
    >
      <v-tab
        v-for="tab in tabs"
        :key="tab.title"
        center-active
      >
        <span class="d-none d-sm-block">{{ tab.title }}</span>
        <v-icon>
          {{ tab.icon }}
        </v-icon>
      </v-tab>
    </v-tabs>

    <!-- tabs item -->
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <account-settings-account
          v-if="userLocal"
          :user-data="userLocal"
        ></account-settings-account>
      </v-tab-item>

      <v-tab-item>
        <account-settings-security></account-settings-security>
      </v-tab-item>

      <v-tab-item>
        <account-settings-info
          v-if="userLocal"
          :user-data="userLocal"
        ></account-settings-info>
      </v-tab-item>

      <v-tab-item>
        <account-settings-notification :user="userLocal"></account-settings-notification>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import {
  mdiAccountOutline, mdiLockOpenOutline, mdiLinkVariant, mdiBellOutline,
} from '@mdi/js'
import { ref } from '@vue/composition-api'

// demos
import AccountSettingsAccount from './AccountSettingsAccount.vue'
import AccountSettingsSecurity from './AccountSettingsSecurity.vue'
import AccountSettingsInfo from './AccountSettingsInfo.vue'
import AccountSettingsNotification from './AccountSettingsNotification.vue'

// Services
import useUser from '@/services/api/modules/user/user'

export default {
  components: {
    AccountSettingsAccount,
    AccountSettingsSecurity,
    AccountSettingsInfo,
    AccountSettingsNotification,
  },
  setup() {
    const userLocal = ref(null)

    // onBeforeCreate
    useUser.fetchUser()
      .then(response => {
        const { user } = response.data
        if (user) {
          userLocal.value = user
        }
      })

    // Tabs
    const tab = ref('')
    const tabs = [
      { title: 'Account', icon: mdiAccountOutline },
      { title: 'Security', icon: mdiLockOpenOutline },
      { title: 'Social', icon: mdiLinkVariant },
      { title: 'Notifications', icon: mdiBellOutline },
    ]

    return {
      // Tab control
      tab,
      tabs,

      // Data
      userLocal,

      icons: {
        mdiAccountOutline,
        mdiLockOpenOutline,
        mdiLinkVariant,
        mdiBellOutline,
      },
    }
  },
}
</script>

<!-- Global CSS: Eliminar espacio de flechas en el Tab Menu cuando el espacio es insuficiente -->
<style lang="scss">

  // v-slide-group__prev, .v-slide-group__next
  .v-slide-group {
    &__prev,
    &__next {
      display: none !important;
    }
  }
</style>
