<template>
  <v-card
    flat
    class="pa-0 mt-2"
  >
    <!-- Push Notifications -->
    <v-card-title>
      <v-icon
        size="26"
        class="text--primary me-3"
      >
        {{ icons.mdiSendOutline }}
      </v-icon>
      <span>{{ $t('AccountSettings.body.tab4.push.title') }}</span>
    </v-card-title>
    <v-card-text v-if="userNotificationLocal.pushNotification">
      <v-switch
        :value="userNotificationLocal.pushNotification.active"
        hide-details
        inset
        class="mt-3"
        @change="onClickPushNotificationToggle"
      >
        <template #label>
          <span class="text-sm ms-2">{{ userNotificationLocal.pushNotification.userSubscription ? 'Actived' : 'Unactived' }}</span>
        </template>
      </v-switch>

      <!-- Check notifications -->
      <div
        v-if="userNotificationLocal.pushNotification.userSubscription"
        class="ml-4"
      >
        <span>Recibir notificaciones de:</span>
        <v-checkbox
          v-for="option in userSubscriptionOptionsFilter"
          :key="option.value"
          v-model="option.value"
          :label="option.label"
        >
        </v-checkbox>
      </div>

      <!-- Subscriptions -->
      <div
        v-if="userNotificationLocal.pushNotification.subscriptions"
        class="ml-4"
      >
        <span><strong>{{ userNotificationLocal.pushNotification.subscriptions.length }} dispositivos registrados</strong></span>
        <v-btn
          v-if="userNotificationLocal.pushNotification.subscriptions.length > 0"
          outlined
          class="mt-1 ml-1"
          color="error"
          @click="onClickPushNotificationDeleteAll"
        >
          BORRAR TODOS
        </v-btn>
      </div>
      <div
        v-if="userNotificationLocal.pushNotification.userSubscription"
        class="ml-4 mb-2"
      >
        <span>
          {{ JSON.stringify(userNotificationLocal.pushNotification.userSubscription) }}
        </span>
      </div>
    </v-card-text>

    <!-- Activity -->
    <v-card-title>
      <v-icon
        size="26"
        class="text--primary me-3"
      >
        {{ icons.mdiChartTimelineVariant }}
      </v-icon>
      <span>{{ $t('AccountSettings.body.tab4.activity.title') }}</span>
    </v-card-title>
    <v-card-text>
      <v-switch
        v-model="userNotificationLocal.followMe"
        hide-details
        inset
        class="mt-3"
      >
        <template #label>
          <span class="text-sm ms-2">{{ $t('AccountSettings.body.tab4.activity.followMe') }}</span>
        </template>
      </v-switch>
    </v-card-text>

    <!-- Application -->
    <v-card-title class="mt-2">
      <v-icon
        size="26"
        class="text--primary me-3"
      >
        {{ icons.mdiEmailOutline }}
      </v-icon>
      <span>{{ $t('AccountSettings.body.tab4.application.title') }}</span>
    </v-card-title>
    <v-card-text>
      <v-switch
        v-model="userNotificationLocal.newsAnnouncements"
        hide-details
        inset
        class="mt-0"
      >
        <template #label>
          <span class="text-sm ms-2">{{ $t('AccountSettings.body.tab4.application.newsAnnouncements') }}</span>
        </template>
      </v-switch>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiSendOutline, mdiChartTimelineVariant, mdiEmailOutline } from '@mdi/js'
import { toRefs } from '@vue/composition-api'

// Services
import { useUserNotificationHandler } from '../useUserNotificationHandler'

export default {
  props: {
    user: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const {
      userNotificationLocal,
      userSubscriptionOptionsFilter,
      onClickPushNotificationToggle,
      onClickPushNotificationDeleteAll,
    } = useUserNotificationHandler(toRefs(props))

    // const userNotificationLocal = ref(JSON.parse(JSON.stringify(props.userData)))

    return {
      userNotificationLocal,
      userSubscriptionOptionsFilter,
      onClickPushNotificationToggle,
      onClickPushNotificationDeleteAll,

      icons: {
        mdiSendOutline,
        mdiChartTimelineVariant,
        mdiEmailOutline,
      },
    }
  },
}
</script>
